/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable linebreak-style */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */

import React from "react";
import { Fade } from "react-awesome-reveal";
// import { Modal } from "../modals/ServiceModal";

export default function Service({ data }) {
  // const [isModalOpen, setIsModalOpen] = React.useState(false);
  // const [selectedItem, setSelectedItem] = React.useState(null);

  // const handleImageClick = (item) => {
  //   setSelectedItem(item);
  //   setIsModalOpen(true);
  // };

  return (
    <div className="bg-gray-50">
      <div className="container mx-auto pt-20 pb-28">
        <Fade direction="right" triggerOnce>
          <h1 className="text-5xl text-theme-blue text-center font-bold">
            Our Services
          </h1>
        </Fade>
        <Fade direction="left" triggerOnce>
          <p className="font-light text-lg text-gray-400 text-center mb-12">
            We provide a full spectrum of software solutions tailored to meet
            your unique needs. Whatever your vision, we are here to bring it to
            life and help your business reach new heights.
          </p>
        </Fade>

        <div className="grid grid-rows-3 px-10 gap-8 sm:grid-cols-3 sm:grid-rows-1 sm:gap-6 xl:gap-16 ">
          {data.map((item, index) => (
            <Fade
              direction={item.animation}
              delay={5 * index}
              key={index}
              triggerOnce
            >
              <div>
                <div
                  className="bg-white dark:bg-gray-900 group rounded-2xl shadow-2xl border border-light-theme-purple dark:border-dark-theme-purple transform transition duration-500 hover:scale-105 cursor-pointer"
                  // onClick={() => handleImageClick(item)}
                >
                  <img
                    src={item.imageUrl}
                    alt="Service"
                    className={`w-full rounded-t-2xl ${
                      ![
                        "Web Development",
                        "Mobile Development",
                        "UI/UX Design",
                      ].includes(item.title)
                        ? "padded-image"
                        : ""
                    }`}
                  />
                  <h2 className="text-theme-blue text-center text-xl py-7 rounded-b-2xl">
                    {item.title}
                  </h2>
                </div>
              </div>
            </Fade>
          ))}
        </div>
      </div>
      {/* <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        item={selectedItem}
      /> */}
    </div>
  );
}
